import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Header from './Header';
import Hero from './Hero';
import Portfolio from './Portfolio';
import USPs from './USPs';
import Packages from './Packages';
import Footer from './Footer';
import Quote from './Quote';
import Join from './Join';
import About from './About';
import loading from './loading.gif'
import RequestAFreeConsultation from './RequestAFreeConsultation';
import reportWebVitals from './reportWebVitals';

// Define your loading component
const Loading = () => (
  <div className="loading-container">
    <img src={loading} alt="Loading..." />
  </div>
);

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay to showcase the loading GIF
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 3000); // Adjust the time as needed

    return () => clearTimeout(timeout);
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <React.StrictMode>
      <Header />
      <Hero />
      <Portfolio />
      <About />
      <USPs />
      <RequestAFreeConsultation />
      <Packages />
      <Quote />
      <Join />
      <Footer />
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

reportWebVitals();

import React from 'react';
import './Desktop.css';
import Links from './Links'; // Assuming Links is a React component

class Desktop extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayLinks: true
    };
  }


  render() {
    return (
      <>
    <div className="desktop-menu">
        <Links />
    </div>
      </>
    );
  }
}

export default Desktop;

import './Portfolio.css';
import makegood from './makegood.png';
import ebig from './ebigrecording.gif';
import ppw from './ppw.png';
import coaching from './coaching.png';
import React, { useState, useEffect } from 'react';
import loading from './loading.gif';

    // Define your loading component
const Loading = () => (
    <div className="loading-container">
      <img src={loading} alt="Loading..." />
    </div>
  );
  
  const Portfolio = () => {
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      // Simulate a delay to showcase the loading GIF
      const timeout = setTimeout(() => {
        setIsLoading(false);
      }, 3000); // Adjust the time as needed
  
      return () => clearTimeout(timeout);
    }, []);
  
    if (isLoading) {
      return <Loading />;
    }
  

    return (
        <div id="portfolio" className="portfolio">
                <h2>Past works</h2>

                <div className="makegood">

                    <div className="makegood-pic">
                    <img src={makegood} alt="Screenshot of MakeGood App website"/>
                    </div>

                    <div className="makegood-text">
                    <h3>MakeGood App</h3>
                    <p>Implemented web design to WordPress for a fintech startup.</p>
                    <a href="https://makegoodapp.com"
                    target="_blank"
                    rel="noopener noreferrer">See more</a>
                    </div>

                </div>

                <div className="ebig">
                
                    <div className="ebig-text">
                    <h3>eBig Design NYC</h3>
                    <p>Designed and implemented our web design to WordPress.</p>
                    </div>

                    <div className="ebig-pic">
                    <img src={ebig} alt="Screenshot of previous eBig Design NYC website"/>
                    </div>

                </div>

                <div className="ppw">

                    <div className="ppw-pic">
                    <img src={ppw} alt="Screenshot of previous eBig Design NYC website"/>
                    </div>

                    <div className="ppw-text">
                    <h3>PPW</h3>
                    <p>Designed and implemented web design to WordPress for a logistics firm.</p>
                    <a href="https://pinoyprideworldwide.com"
                    target="_blank"
                    rel="noopener noreferrer">See more</a>
                    </div>

                </div>

                <div className="coaching">

                    <div className="coaching-text">
                    <h3>The SEO House Program</h3>
                    <p>Designed and implemented web design to Wix for a business mentorship program.</p>
                    <a
                    href="https://amindanao96.wixsite.com/angel-at-ebig-design"
                    target="_blank"
                    rel="noopener noreferrer"
                    >See more</a>
                    </div>

                    <div className="coaching-pic">
                    <img src={coaching} alt="Screenshot of previous eBig Design NYC website"/>
                    </div>

                </div>
        </div>
    )
}

export default Portfolio;
import './Join.css'

const Join = () => {

    return (
        <div
        className="join"
        >
            <div class="content">
                <h2>Join our subscribers</h2>
                <p> Stay up-to-date about technology, web development, AI, and more.</p>
                <a
                             target="_blank"
                             rel="noopener noreferrer"
                href="https://ebigdesignnyc.substack.com/">
                <button className="join-myBtn">Sign up</button>
                </a>
                </div>
        </div>
    )
}

export default Join;
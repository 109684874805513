import './Footer.css';
import logo from './logowithborder.png';

const Footer = () => {

    return (
        <div className="footer">
            <div>
                <a href="./">
                <img
                src= {logo}
                alt="eBig Design NYC Logo"
                />
                </a>
            </div>
            <div>
                <a
                href="./#about"
                class="footer-about">
                        About
                </a>
                <a
                href="./#portfolio"
                class="footer-about">
                        Portfolio
                </a>
                <a
                href="./#request"
                class="footer-about">
                        Contact
                </a>
                <a
                href="./#services"
                class="footer-about">
                        Services
                </a>
            </div>
        </div>
    )
}

export default Footer;

import './Header.css';
import logo from './logowithborder.png';
import NavClassComponent from './NavClassComponent';
import Desktop from './Desktop'

function Header() {
  return (
    <div className="Header">
      <header className="top">
        <a href="./">
        <img src={logo} className="header-logo" alt="eBig Design NYC Logo" />
        </a>
        
        <div className="mobile">
          <NavClassComponent/>
        </div>

        <div className="desktop">
          <Desktop />
          </div>
      </header>
    </div>
  );
}

export default Header;

import './Quote.css'

const Quote = () => {

    return (
        <div
        className="quote"
        >
            <div class="quote-content">
                <h2>“After few months of web developing, eBig Design NYC was able to make [the site] eye-catching and appealing to anyone who visits the website.”</h2>
                <p> Edgar Ocampo </p>
                <small className="quote-myBtn">CEO and Founder of PPW</small>
                </div>
        </div>
    )
}

export default Quote;
import './About.css'

const About = () => {

    return (
        <div
        id="about"
        className="about"
        >
            <div class="about-content">
                <h2>About us</h2>
                <p>
                eBig Design NYC was established in 2023 to fill the demand for small business needs, including ranking on search engines and creating a digital platform for their services.
                Our mission? To create innovative digital solutions that transform businesses and enrich user experiences by delivering cutting-edge web development services, fostering a vibrant and diverse work environment, and contributing to the growth of NYC firms.
                We value diversity and inclusion; collaboration; innovation; and excellence.
                Our specialists in partnerships, design, and engineering are ready to help every step of the way.
                Lastly, the five steps in our process are: requirements gathering, design, development, testing, and deployment.
                </p>
                </div>
        </div>
    )
}

export default About;
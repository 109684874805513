import './Packages.css'

const Packages = () => {

    return (
        <div
        id="services"
        className="packages"
        >

            <div className="packages-heading">
                <h2>Services</h2>
            </div>

            <div className="list">

                <div className="Bronze">
                    <h3>Bronze Package ($1,000)</h3>
                        <li>Landing page design</li>
                        <li>Marketing consulting</li>
                        <li>One design draft</li>
                        <li>Three-week timeline</li>
                        <a
                        href="https://square.link/u/JZYeK0VV"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="packages-myBtn">Buy now</a>
                </div>

                <div className="Silver">
                    <h3>Silver Package ($5,000)</h3>
                        <li>SEO development</li>
                        <li>Landing page</li>
                        <li>Digital marketing consulting</li>
                        <li>Two design drafts</li>
                        <li>Wireframing and implementation</li>
                        <li>Four-week timeline</li>
                        <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://square.link/u/4WP4hC7E"
                        className="packages-myBtn">Buy now</a>

                </div>

                <div className="Gold">
                    <h3>Gold Package ($10,000)</h3>
                        <li>SEO development</li>
                        <li>Full website</li>
                        <li>Marketing and technology consulting</li>
                        <li>Three design drafts</li>
                        <li>Wireframing and implementation</li>
                        <li>Four-week timeline</li>
                        <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://square.link/u/dT5u7pnY"
                        className="packages-myBtn">Buy now</a>

                </div>
                
            </div>


        </div>
    )
}

export default Packages;